import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';
import {
	ConditionType,
	DeviceFamilyTypes,
	ExcludedAiSettings,
	ExcludedAiSettingsHello3,
	IncludedAiSettings,
	ObservationType,
	PatientAiSetting,
	PrecautionTriggerValues,
	RailType,
	SensitivityType,
} from 'constants/enums.js';
import { Alert, CustomDropdown } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { getPrecautionObservations, getPrecautions, setPrecautions } from 'api/monitoring.js';
import { deleteConditions, getPrecautions as getPrecautionsEhr, setConditions } from 'api/whiteboard.js';
import _ from 'lodash';
import {
	AiSetting,
	CallWorkflowType,
	SettingsCategory,
	UserSettingTypes,
	configurableAISettings,
} from 'constants/configurationEnums.js';
import { getAiSettingsConfigurations, getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { getRoomSettings } from 'api/adminConfigurations.js';
import { getUserPreferences } from 'api/users.js';
import { updatePatientAiSettings } from 'api/patients.js';
import ToastMessage from 'components/ToastMessage.jsx';
import { isAnySettingActive } from 'infrastructure/helpers/aiHelper.js';
import Button from 'components/Button.jsx';
import { FallPreventionSettingTypes, FallPreventionTypes } from 'constants/ai.js';
import PrecautionsIcon from 'icons/Monitoring/PrecautionsIcon.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { PrecautionsObservationCodes } from 'constants/monitoring.js';

const initialPrecautions = [
	{
		id: '22631001',
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#F3C752',
	},
	{
		id: '77272004',
		abbreviation: 'SZ',
		name: 'Seizure',
		textColor: '#FFFFFF',
		boxColor: '#E270DA',
	},
	{
		id: '441862004',
		abbreviation: 'ISO',
		name: 'Isolation',
		textColor: '#FFFFFF',
		boxColor: '#D64F2D',
	},
	{
		id: '413322009',
		abbreviation: 'SW',
		name: 'Suicide Watch',
		textColor: '#282D30',
		boxColor: '#88D9FB',
	},
	{
		id: '26544005',
		abbreviation: 'SB',
		name: 'Suspicious Behavior',
		textColor: '#FFFFFF',
		boxColor: '#7B35C1',
	},
	{
		id: '49436004',
		abbreviation: 'HP',
		name: 'Hospice',
		textColor: '#FFFFFF',
		boxColor: '#000000',
	},
	{
		id: '71388002',
		abbreviation: 'D',
		name: 'Detox',
		textColor: '#282D30',
		boxColor: '#F2A356',
	},
	{
		id: '233604007',
		abbreviation: 'PO2',
		name: 'Pulling O2',
		textColor: '#282D30',
		boxColor: '#B6D7E4',
	},
	{
		id: '35489007',
		abbreviation: 'IC',
		name: 'Impulsive/Confused',
		textColor: '#282D30',
		boxColor: '#D3D3D3',
	},
	{
		id: '43998006',
		abbreviation: 'NR',
		name: 'Non-redirectable',
		textColor: '#FFFFFF',
		boxColor: '#4BA5F8',
	},
];

const ehrPrecautions = [
	{
		id: PrecautionsObservationCodes.FALLS_CODE,
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
		isFallPrecaution: true,
	},
	{
		id: PrecautionsObservationCodes.FALL_RISK_SCORE_CODE,
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
		isFallPrecaution: true,
	},
	{
		id: PrecautionsObservationCodes.FALL_RISK_SCALE_CODE,
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
		isFallPrecaution: true,
	},
	{
		id: PrecautionsObservationCodes.IBED_FALL_RISK_CODE,
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
		isFallPrecaution: true,
	},
	{
		id: PrecautionsObservationCodes.HUMPTY_DUMPTY_CODE,
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
		isFallPrecaution: true,
	},
	{
		id: 'PRE2',
		abbreviation: 'SZ',
		name: 'Seizure',
		textColor: '#FFFFFF',
		boxColor: '#FFD600',
		icon: 'seizure.svg',
	},
	{
		id: 'PRE3',
		abbreviation: 'SW',
		name: 'Suicide Watch',
		textColor: '#fff',
		boxColor: '#500772',
		icon: 'suicide.svg',
	},
	{
		id: 'PRE6',
		abbreviation: 'AS',
		name: 'Aspiration',
		textColor: '#fff',
		boxColor: '#FFD600',
		icon: 'aspiration.svg',
	},
	{
		id: '140133578',
		abbreviation: 'BSP',
		name: 'Behavioral Safety Plan',
		textColor: '#fff',
		boxColor: '#7B2D9F',
		icon: 'behavioral.svg',
	},
	{
		id: '26544005',
		abbreviation: 'SB',
		name: 'Suspicious Behavior',
		textColor: '#FFFFFF',
		boxColor: '#7B35C1',
	},
];

const PrecautionsBox = ({
	deviceId,
	roomId,
	deviceOwnerId,
	aiSettings,
	toggleFallPrevention,
	isDefaultOwner,
	deviceFamily,
	showPrecautions,
	isEhrField,
	sbFeatureFlagOn,
}) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const [error, setError] = useState(null);
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);
	const setAiSettingsAction = ai => dispatch(aiSettingsActionCreators.setPatientAiSettings(ai));
	const [adminAiSettingsConfigurations, setAdminAiConfigurations] = useState(configurableAISettings());
	const [expandedBox, setExpandedBox] = useState(true);
	const [isMoreThanOneAi, setIsMoreThanOneAi] = useState(false);
	const [precautions, setPrecautionsList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const socket = useContext(SocketContext);
	const suspiciousConditionCode = '26544005';

	const getTypeOfFallPrecaution = (precaution, code) =>
		precaution?.code === code && precaution?.observationTypeId === ObservationType.BRADEN_SCORE;

	useEffect(() => {
		const getActiveItem = precaution => {
			const isFallPrecaution =
				[PrecautionsObservationCodes.FALLS_CODE, PrecautionsObservationCodes.FALL_RISK_SCORE_CODE].includes(precaution?.code) &&
				precaution?.observationTypeId === ObservationType.BRADEN_SCORE;

			if (isFallPrecaution) {
				return (precaution?.valueString || precaution?.value) && (+precaution?.valueString >= 7 || +precaution?.value >= 7);
			}
			if (getTypeOfFallPrecaution(precaution, PrecautionsObservationCodes.IBED_FALL_RISK_CODE)) {
				return (
					(precaution?.valueString || precaution?.value) &&
					precaution?.valueString?.toLowerCase() === PrecautionTriggerValues.IBED_FALL_RISK.toLowerCase()
				);
			}
			if (getTypeOfFallPrecaution(precaution, PrecautionsObservationCodes.HUMPTY_DUMPTY_CODE)) {
				return (
					(precaution?.valueString || precaution?.value) &&
					precaution?.valueString?.toLowerCase() === PrecautionTriggerValues.PED_FALL_RISK.toLowerCase()
				);
			}
			if (getTypeOfFallPrecaution(precaution, PrecautionsObservationCodes.FALL_RISK_SCALE_CODE)) {
				return (
					(precaution?.valueString || precaution?.value) &&
					precaution?.valueString?.toLowerCase() === PrecautionTriggerValues.FALL_RISK_SCALE.toLowerCase()
				);
			}
			return !!precaution;
		};
		const getUpdatedData = (arr, condition, observation) => {
			return arr.map(item => {
				let foundPrecautions = [];
				let foundPrecaution = null;
				if (isEhrField) {
					foundPrecautions = observation.observations.filter(p => p.code === item.id);
					if (foundPrecautions?.length > 0) {
						foundPrecaution = foundPrecautions.reduce((latest, current) => {
							const latestDate = new Date(latest.effectiveDateTime);
							const currentDate = new Date(current.effectiveDateTime);
							return currentDate > latestDate ? current : latest;
						}, foundPrecautions[0]);
					}
				}
				const precaution = condition.conditions.find(p => p.code === item.id) || (isEhrField && foundPrecaution);
				return {
					...item,
					active: getActiveItem(precaution),
					effectiveDateTime: precaution?.effectiveDateTime,
				};
			});
		};

		const fetchPrecautions = async () => {
			setIsLoading(true);
			if (isEhrField) {
				const [conditionsRes, observationsRes] = await Promise.all([
					getPrecautionsEhr(deviceOwnerId),
					getPrecautionObservations(deviceOwnerId),
				]);
				if (conditionsRes.error || observationsRes.error) {
					setError(translate('somethingWentWrong'));
					return;
				}
				const newPrecautions = getUpdatedData(ehrPrecautions, conditionsRes, observationsRes);
				setPrecautionsList(newPrecautions);
				return;
			}
			const response = await getPrecautions(deviceOwnerId);
			if (response.error) {
				setError(translate('somethingWentWrong'));
				return;
			}
			const newPrecautions = getUpdatedData(initialPrecautions, response);
			setPrecautionsList(newPrecautions);
		};

		if (!isDefaultOwner && deviceOwnerId && showPrecautions) {
			fetchPrecautions();
			setIsLoading(false);
		}
	}, [deviceOwnerId, isDefaultOwner, isEhrField, showPrecautions]);

	useEffect(() => {
		const handleConditionAdded = data => {
			if (!data.deviceId || !data.conditions || data.deviceId !== deviceId) {
				return;
			}
			if (!isEhrField) {
				let newPrecautions = _.cloneDeep(initialPrecautions);
				if (data.conditions.length === 0) {
					setPrecautionsList(newPrecautions);
					return;
				}
				if (
					data.conditions.length > 0 &&
					data.conditions.filter(item => item.conditionType === ConditionType.PRECAUTION).length === 0
				) {
					return;
				}
				newPrecautions = _.cloneDeep(initialPrecautions).map(precaution => {
					const foundPrecaution = data.conditions.find(condition => condition.code === precaution.id);
					if (foundPrecaution) {
						precaution.active = true;
					}
					return precaution;
				});
				setPrecautionsList(newPrecautions);
				return;
			}
			setPrecautionsList(prevPrecautions => {
				const newPrecautions = ehrPrecautions.map(precaution => {
					const foundPrecaution = data.conditions.find(condition => condition.code === precaution.id);
					const newPrecaution = { ...precaution };
					if (foundPrecaution) {
						newPrecaution.active = true;
						return newPrecaution;
					} else {
						const found = prevPrecautions.find(x => x.id === precaution.id);
						if (found) {
							newPrecaution.active = !!found?.active;
							newPrecaution.effectiveDateTime = found?.effectiveDateTime;
						}
						return newPrecaution;
					}
				});
				return newPrecautions;
			});
		};

		const handleConditionRemoved = data => {
			if (!data.deviceId || !data.conditions || data.deviceId !== deviceId || !isEhrField) {
				return;
			}
			setPrecautionsList(prevPrecautions => {
				const newPrecautions = prevPrecautions.map(precaution => {
					const foundPrecaution = data.conditions.find(condition => condition.code === precaution.id);
					if (foundPrecaution && precaution?.active) {
						const newPrecaution = { ...precaution };
						newPrecaution.active = false;
						return newPrecaution;
					}
					return precaution;
				});

				return newPrecautions;
			});
		};
		const handleObservationsAdded = data => {
			if (deviceId !== data.deviceId || !isEhrField) {
				return;
			}
			const getActiveItem = precaution => {
				const isFallPrecaution =
					[PrecautionsObservationCodes.FALLS_CODE, PrecautionsObservationCodes.FALL_RISK_SCORE_CODE].includes(precaution?.code) &&
					precaution?.observationTypeId === ObservationType.BRADEN_SCORE;

				if (isFallPrecaution) {
					return (precaution?.valueString || precaution?.value) && (+precaution?.valueString >= 7 || +precaution?.value >= 7);
				}
				if (getTypeOfFallPrecaution(precaution, PrecautionsObservationCodes.IBED_FALL_RISK_CODE)) {
					return (
						(precaution?.valueString || precaution?.value) &&
						precaution?.valueString?.toLowerCase() === PrecautionTriggerValues.IBED_FALL_RISK.toLowerCase()
					);
				}
				if (getTypeOfFallPrecaution(precaution, PrecautionsObservationCodes.HUMPTY_DUMPTY_CODE)) {
					return (
						(precaution?.valueString || precaution?.value) &&
						precaution?.valueString?.toLowerCase() === PrecautionTriggerValues.PED_FALL_RISK.toLowerCase()
					);
				}
				if (getTypeOfFallPrecaution(precaution, PrecautionsObservationCodes.FALL_RISK_SCALE_CODE)) {
					return (
						(precaution?.valueString || precaution?.value) &&
						precaution?.valueString?.toLowerCase() === PrecautionTriggerValues.FALL_RISK_SCALE.toLowerCase()
					);
				}
				return !!precaution;
			};
			setPrecautionsList(prevPrecautions => {
				const relevantObservations = data.observations?.filter(observation =>
					ehrPrecautions?.some(precaution => precaution.id === observation.code)
				);

				const newPrecautions = ehrPrecautions.map(item => {
					const foundPrecautions = relevantObservations?.filter(p => p.code === item.id);
					let foundPrecaution = null;
					if (foundPrecautions?.length > 0) {
						foundPrecaution = foundPrecautions.reduce((latest, current) => {
							const latestDate = new Date(latest.effectiveDateTime);
							const currentDate = new Date(current.effectiveDateTime);
							return currentDate > latestDate ? current : latest;
						}, foundPrecautions[0]);
					}
					const newPrecaution = { ...item };

					if (foundPrecaution) {
						newPrecaution.active = getActiveItem(foundPrecaution);
						newPrecaution.effectiveDateTime = foundPrecaution?.effectiveDateTime;
					} else {
						const found = prevPrecautions.find(x => x.id === item.id);
						if (found) {
							newPrecaution.active = !!found?.active;
							newPrecaution.effectiveDateTime = found?.effectiveDateTime;
						}
					}

					return newPrecaution;
				});

				return newPrecautions;
			});
		};
		const handleObservationsRemoved = data => {
			if (deviceId !== data.deviceId || !isEhrField) {
				return;
			}
			setPrecautionsList(prevPrecautions => {
				const newPrecautions = prevPrecautions.map(precaution => {
					const foundPrecaution = data.observations.find(condition => condition.code === precaution.id);
					if (foundPrecaution && precaution?.active) {
						const newPrecaution = { ...precaution };
						newPrecaution.active = false;
						newPrecaution.effectiveDateTime = foundPrecaution.effectiveDateTime;
						return newPrecaution;
					}
					return precaution;
				});

				return newPrecautions;
			});
		};
		socket.on(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_ADDED, handleObservationsAdded);
		socket.on(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_REMOVED, handleObservationsRemoved);
		socket.on(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED, handleConditionAdded);
		socket.on(SocketEvents.HealthCare.PATIENT_CONDITIONS_REMOVED, handleConditionRemoved);
		return () => {
			socket.off(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_ADDED, handleObservationsAdded);
			socket.off(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_REMOVED, handleObservationsRemoved);
			socket.off(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED, handleConditionAdded);
			socket.off(SocketEvents.HealthCare.PATIENT_CONDITIONS_REMOVED, handleConditionRemoved);
		};
	}, [socket, deviceId, intl, isEhrField]);

	useEffect(() => {
		const fetchRoomSettings = async () => {
			setIsLoading(true);
			const [adminAiSettings, aiRoomSettings] = await Promise.all([
				getRoomSettings(roomId, SettingsCategory.AI_SETTINGS),
				getUserPreferences(UserSettingTypes.AiSettings, roomId),
			]);
			const aiResponse = getAiSettingsConfigurations(adminAiSettings, aiRoomSettings);
			if (aiResponse.error) {
				setError(aiResponse.error);
			} else {
				setAdminAiConfigurations(aiResponse.configs);
			}
		};

		fetchRoomSettings();
		setIsLoading(false);
	}, [roomId]);

	useEffect(() => {
		const toastTimeOut = setTimeout(() => {
			setIsMoreThanOneAi(false);
		}, 3000);

		return () => {
			clearTimeout(toastTimeOut);
		};
	}, [isMoreThanOneAi]);

	const isAnyFallPreventionConfigEnabled = () =>
		getConfigurationValue(adminAiSettingsConfigurations[AiSetting.RAILS]) ||
		getConfigurationValue(adminAiSettingsConfigurations[AiSetting.GET_OUT_OF_BED]) ||
		getConfigurationValue(adminAiSettingsConfigurations[AiSetting.FALL_DETECTION]);

	const shouldDisableClick = (settingTypeId, value) => {
		const settings = _.cloneDeep(aiSettingList);
		const found = settings.find(item => item.deviceId === deviceId);

		const foundInFallPrevention = FallPreventionTypes.find(item => item === settingTypeId);
		const excludedSettings = deviceFamily === DeviceFamilyTypes.HELLO_3 ? ExcludedAiSettingsHello3 : ExcludedAiSettings;
		if (!found) {
			return true;
		}
		let isAiSettingEnabled = isAnySettingActive(found.settings, ExcludedAiSettings, settingTypeId);
		const isIndependentAiActive = isAnySettingActive(found.settings, IncludedAiSettings, settingTypeId, true);
		if (foundInFallPrevention) {
			isAiSettingEnabled = found.settings
				.filter(el => !ExcludedAiSettings.includes(el.settingTypeId))
				.reduce((acc, item) => {
					return (
						acc || (!FallPreventionTypes.includes(item.settingTypeId) && item.isEnabled && item.settingTypeId !== settingTypeId)
					);
				}, false);
		}
		return (
			isAiSettingEnabled &&
			value &&
			settingTypeId !== PatientAiSetting.HAND_WASHING &&
			(!excludedSettings.includes(settingTypeId) || isIndependentAiActive)
		);
	};

	const submitFallPrevention = async status => {
		if (shouldDisableClick(PatientAiSetting.FALL_PREVENTION, status)) {
			setIsMoreThanOneAi(true);
			return;
		}
		const isSettingEnabled = type => getConfigurationValue(adminAiSettingsConfigurations[type]);
		const dataToSend = [
			{
				settingTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
				value: isSettingEnabled(AiSetting.GET_OUT_OF_BED) ? SensitivityType.MEDIUM : null,
				isEnabled: isSettingEnabled(AiSetting.GET_OUT_OF_BED) && status,
			},
			{
				settingTypeId: PatientAiSetting.RAILS,
				value: isSettingEnabled(AiSetting.RAILS) ? `${RailType.TOP_RIGHT}-${RailType.TOP_LEFT}` : null,
				isEnabled: isSettingEnabled(AiSetting.RAILS) && status,
			},
			{
				settingTypeId: PatientAiSetting.FALL_DETECTED,
				value: isSettingEnabled(AiSetting.FALL_DETECTION) ? 'true' : 'false',
				isEnabled: isSettingEnabled(AiSetting.FALL_DETECTION) && status,
			},
		];
		toggleFallPrevention(status, deviceId);
		const found = aiSettingList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		const settings = _.cloneDeep(aiSettingList);
		const initialSettings = settings.find(item => item.deviceId === deviceId).settings;
		const filteredSettings = _.cloneDeep(initialSettings).filter(
			item => !FallPreventionSettingTypes.includes(item.settingTypeId)
		);
		setAiSettingsAction({
			settings: [...filteredSettings, ...dataToSend],
			deviceId: deviceId,
		});
		const sendData = dataToSend.map(item => ({
			isEnabled: item.isEnabled,
			settingTypeId: item.settingTypeId,
			workflowType: CallWorkflowType.MONITORING,
		}));
		const params = {
			patientId: deviceOwnerId,
			deviceId,
			roomId,
			sendData,
		};
		const response = await updatePatientAiSettings(params);
		if (response.error) {
			setError(response.error.message);
			setAiSettingsAction({
				settings: initialSettings,
				deviceId: deviceId,
			});
			return;
		}
	};

	const setPrecautionOptions = items => {
		if (sbFeatureFlagOn && isEhrField) {
			toggleSuspiciousBehavior();
			return;
		}
		setCustomPrecautions(items);
	};

	const setCustomPrecautions = async items => {
		const precautionsInitial = [...precautions];
		const noPrecautions = [
			{
				code: '00000000',
				name: 'Stability',
				conditionType: 3,
			},
		];

		const newPrecautions = precautionsInitial.map(precaution => {
			const newPrecaution = { ...precaution };
			newPrecaution.active = !!items.find(item => item.value === precaution.id);
			return newPrecaution;
		});
		const dataToSend = newPrecautions.filter(x => x.active).map(x => ({ conditionType: 3, code: x.id }));
		const response = await setPrecautions(deviceOwnerId, {
			deviceId,
			conditions: dataToSend.length > 0 ? dataToSend : noPrecautions,
		});

		if (response.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			return;
		}
		const fallsPrecautionsCode = '22631001';
		let fallPreventionStatus = false;
		const foundAiSettings = aiSettings.find(item => item.deviceId === deviceId);
		if (foundAiSettings) {
			fallPreventionStatus = foundAiSettings.settings.reduce((acc, item) => {
				if (FallPreventionSettingTypes.includes(item.settingTypeId)) {
					return acc || item.isEnabled;
				}
				return acc;
			}, false);
		}
		if (
			items.find(item => item.value === fallsPrecautionsCode) &&
			!fallPreventionStatus &&
			dataToSend.find(precaution => precaution.code === fallsPrecautionsCode) &&
			isAnyFallPreventionConfigEnabled()
		) {
			submitFallPrevention(true);
		}
		if (
			precautionsInitial.find(item => item.id === fallsPrecautionsCode)?.active &&
			!items.find(item => item.value === fallsPrecautionsCode)
		) {
			submitFallPrevention(false);
		}
		setPrecautionsList(newPrecautions);
	};

	const handleKeyDown = event => {
		if (event.key === 'Backspace' && !event.target.value) {
			event.preventDefault();
		}
	};

	const toggleSuspiciousBehavior = async () => {
		if (!deviceOwnerId) {
			return;
		}
		const suspiciousConditionObj = {
			deviceId: deviceId,
			conditions: [
				{
					conditionType: ConditionType.PRECAUTION,
					display: 'Suspicious Behavior',
					code: suspiciousConditionCode,
				},
			],
		};
		const isActive = precautions.find(item => item.id === suspiciousConditionCode && item.active);
		const response = isActive
			? await deleteConditions(deviceOwnerId, suspiciousConditionObj)
			: await setConditions(deviceOwnerId, suspiciousConditionObj);

		if (response.error) {
			setError(translate('somethingWentWrong'));
			return;
		}
		const newPrecautions = precautions.map(precaution => {
			if (precaution.id === suspiciousConditionCode) {
				const newPrecaution = { ...precaution };
				newPrecaution.active = !newPrecaution.active;
				return newPrecaution;
			}
			return precaution;
		});
		setPrecautionsList(newPrecautions);
	};

	const filteredPrecautions = precautions?.reduce((acc, item) => {
		if (item.id === PrecautionsObservationCodes.FALLS_CODE && item.active) {
			if (!acc.some(existing => existing.id === PrecautionsObservationCodes.FALLS_CODE)) {
				acc.push(item);
			}
			return acc;
		}

		if (item.isFallPrecaution && !precautions.find(pre => pre.id === PrecautionsObservationCodes.FALLS_CODE)?.active) {
			const latestFallPrecaution = precautions
				.filter(fallItem => fallItem.isFallPrecaution && fallItem.effectiveDateTime)
				.sort((a, b) => new Date(b.effectiveDateTime) - new Date(a.effectiveDateTime));

			if (latestFallPrecaution.length > 0 && latestFallPrecaution[0].active) {
				if (!acc.some(existing => existing.id === latestFallPrecaution[0].id)) {
					acc.push(latestFallPrecaution[0]);
				}
			}
		} else if (item.active && !item.isFallPrecaution) {
			acc.push(item);
		}

		return acc;
	}, []);

	let initialOptions = initialPrecautions.map(item => ({
		value: item.id,
		label: item.name,
	}));

	if (isEhrField) {
		initialOptions = ehrPrecautions
			.filter(item => item.id === suspiciousConditionCode)
			.map(item => ({
				value: item.id,
				label: item.name,
			}));
	}

	return (
		<div className='monitoring-timeline-box precautions-box'>
			<div className={classNames('timeline-box-header', expandedBox ? 'expanded' : '')}>
				<p className='timeline-box-title'>
					<PrecautionsIcon />
					{translate('precautions')}
				</p>
				<div className='timeline-box-actions'>
					<Button
						border='none'
						onClick={() => setExpandedBox(prevState => !prevState)}
						icon={expandedBox ? 'expand_less' : 'expand_more'}
					/>
				</div>
			</div>

			{!isLoading && showPrecautions && !isDefaultOwner && expandedBox && deviceOwnerId && (
				<>
					<div
						className={classNames('flex full-height text-align-center gap-m padding-other-top-m flex-wrap', {
							'top-15': filteredPrecautions.length > 0,
						})}>
						{filteredPrecautions.map(item => (
							<div
								className='precaution-box'
								style={{
									background: item.boxColor,
								}}
								data-tooltip={item.name}
								data-position='top'>
								{(!isEhrField || item.id === suspiciousConditionCode) && (
									<span style={{ color: item.textColor }}>{item.abbreviation}</span>
								)}
								{isEhrField && item.id !== suspiciousConditionCode && (
									<img src={`${healthCareCdnUrl}monitoring/precautions/${item.icon}`} alt='icon' />
								)}
							</div>
						))}
						{(!isEhrField || sbFeatureFlagOn) && (
							<div className='flex-basis-100'>
								<CustomDropdown
									defaultOptions={
										filteredPrecautions?.length > 0
											? filteredPrecautions.map(item => ({
													value: item.id,
													label: item.name,
											  }))
											: []
									}
									initialOptions={initialOptions}
									onSelect={setPrecautionOptions}
									title={intl.formatMessage({ id: 'selectPrecautions' })}
									showTitleInPlaceholder={true}
									placeholder=''
									isSearchable={true}
									handleKeyDown={handleKeyDown}
									isDisabled={isEhrField && !sbFeatureFlagOn}
									closeMenuOnSelect={sbFeatureFlagOn && isEhrField}
								/>
							</div>
						)}
					</div>
					<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
					<ToastMessage
						showToast={isMoreThanOneAi}
						onClose={() => setIsMoreThanOneAi(false)}
						className='video-feed-toast-message'>
						<span>{translate('noMoreThanOneAiType')}</span>
					</ToastMessage>
				</>
			)}
		</div>
	);
};

export default PrecautionsBox;
